import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, mergeMap} from 'rxjs/operators';
import {MetaResponse} from '../shared/interfaces/meta-response';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends BaseService {


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    requestAlarmsDataFromConfig(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.configuration.dashboard;
        return this.http.get( url ).pipe(
            mergeMap(response => {
                return of(response);
            }),
            catchError(error => this.mapToMetaResponse(error, true)),
        );
    }

    saveAlarmDataToConfig(payload: any): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.configuration.dashboard;
        return this.http.post(url, payload).pipe(
            mergeMap(response => {
                return of(response);
            }),
            catchError(error => this.mapToMetaResponse(error, true)),
        );
    }


    requestDashboardConfiguration(): Observable<MetaResponse> {
        const url = this.API_BASE_URL + constants.api.routes.configuration.dashboard;
        return this.http.get( url ).pipe(
            mergeMap(response => of(this.mapDefault(response))),
            mergeMap((responseData: string) => this.parseStoredJSONData(responseData)),
            mergeMap(userStore => this.mapToMetaResponse(userStore, false)),
            catchError(error => this.mapToMetaResponse(error, true)),
        );
    }


    private parseStoredJSONData(data: string): Observable<Error | ConfigurationUserStore> {
        return new Observable<Error | ConfigurationUserStore>(
            (observer) => {
                try {
                    const parsedData = JSON.parse(data);
                    if (!('power_checker' in parsedData)) {
                        observer.error(new Error('PowerChecker config missing'));
                    }
                    if ('name' in parsedData.power_checker && 'room' in parsedData.power_checker) {
                        observer.next(parsedData as ConfigurationUserStore);
                    } else {
                        observer.error(new Error('PowerChecker config attributes missing'));
                    }
                } catch (error) {
                    observer.error(error);
                }
                observer.complete();
            }
        );
    }
}

export interface ConfigurationUserStore {
    power_checker?: {
        room?: string;
        name?: string;
    };
}
