<div class="blackbox">
    <div class="version">
        <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
    </div>
</div>

<nav class="-block-menu">
    <div class="-logo"></div>

    <ul class="-menu-entries">
        <li>
            <img class="menu-icon" src="assets/img/icons/dashboard-icon-esc.png" alt="Dashboard Icon" height="20" width="20"/>
            <a routerLink="/" title="{{ 'screens.menu.overview' | translate }}"
               [ngClass]="{active : activeElement == 'dashboard'}">{{ 'screens.menu.overview' | translate }}</a>
        </li>
        <li>
            <a routerLink="/meine-daten" title="{{ 'common.myData' | translate }}"
               [ngClass]="{active : activeElement == 'profile'}">{{ 'common.myData' | translate }}</a>
        </li>
        <li>
            <a routerLink="/mein-haushalt" title="{{ 'screens.menu.myHousehold' | translate }}"
               [ngClass]="{active: activeElement === 'household'}">{{ 'screens.menu.myHousehold' | translate }}</a>
        </li>
        <li>
            <a routerLink="/einstellungen" title="{{ 'screens.menu.settings' | translate }}"
               [ngClass]="{active: activeElement === 'settings'}">{{ 'screens.menu.settings' | translate }}</a>
        </li>
        <li>
            <a routerLink="/kontakt" title="{{ 'common.contact' | translate }}"
               [ngClass]="{active: activeElement === 'contact'}">{{ 'common.contact' | translate }}</a>
        </li>
        <li>
            <a routerLink="/hilfe" title="{{ 'screens.menu.help' | translate }}"
               [ngClass]="{active: activeElement === 'help'}">{{ 'screens.menu.help' | translate }}</a>
        </li>
        <li>
            <a href="javascript:;"
               title="{{ application.isDemoMode() ? ('screens.menu.exitDemoMode' | translate) : ('screens.menu.logout' | translate) }}"
               (click)="onLogout()">{{ application.isDemoMode() ? ('screens.menu.exitDemoMode' | translate) : ('screens.menu.logout' | translate) }}</a>
        </li>
    </ul>

</nav>

<div class="block block-submenu">
    <div class="inner">
        <nav class="submenu">
            <ul>
                <li><a routerLink="/impressum" title="{{ 'screens.menu.imprint' | translate }}" class="eon-regular"
                       angulartics2On="click" angularticsCategory="screens.menu.menu" angularticsAction="view_imprint"
                >{{ 'screens.menu.imprint' | translate }}</a></li>
                <li>
                    <a [href]="privacyLink" target="_blank" title="{{ 'screens.menu.privacyPolicy' | translate }}"
                       class="eon-regular"
                       angulartics2On="click" angularticsCategory="screens.menu.menu" angularticsAction="view_privacy"
                    >{{ 'screens.menu.privacyPolicy' | translate }}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>
