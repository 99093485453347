<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">
            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'screens.dashboard.realTimeAlert.title' | translate }}</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Verbrauchs-Alarm"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="onClose()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <div class="tab-bar">
                <div [class.active]="currentMode === alarmViewMode.OVERVIEW"
                     (click)="setMode(alarmViewMode.OVERVIEW)">
                    {{ 'screens.dashboard.realTimeAlert.overviewTab' | translate }}
                </div>
                <div [class.active]="currentMode === alarmViewMode.MANAGE"
                     (click)="setMode(alarmViewMode.MANAGE)">
                    {{ 'screens.dashboard.realTimeAlert.manageTab' | translate }}
                </div>
                <div class="grow-space"></div>
            </div>

            <div class="detail-content" *ngIf="currentMode === alarmViewMode.OVERVIEW">
                <section class="detail-content-graph">
                    <div class="graph-legends">
                        <div>
                            <span class="dot consumption"></span> {{ 'screens.dashboard.comparison.consumption' | translate }}
                        </div>
                        <div>
                            <span class="dot feedin"></span>{{ 'screens.dashboard.comparison.feedIn' | translate }}
                        </div>
                    </div>
                    <ng-container *ngIf="!showEmptyState; else emptyState">
                        <app-real-time-alert-chart
                                [isDetail]="true"
                                (chartLoaded)="onChartLoaded($event)">
                        </app-real-time-alert-chart>

                        <div class="diagram-controls">
                            <div class="left">
                                <button class="eon-icon-button small"
                                        [disabled]="currentDataOffset === maxDataOffset"
                                        (click)="stepBack()">
                                    <i class="chevron-left-s white"></i>
                                </button>
                            </div>
                            <div class="right">
                                <button class="eon-icon-button small"
                                        [disabled]="currentDataOffset === 0"
                                        (click)="stepForward()">
                                    <i class="chevron-right-s white"></i>
                                </button>
                                <div class="m-r-m"></div>
                                <button class="eon-button-new small"
                                        [disabled]="currentDataOffset === 0"
                                        (click)="resetHistory()">
                                    {{ 'common.now' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </section>
                <div class="detail-sidebar">
                    <div class="meter">
                        <div class="details">
                            <img [src]="determineMeterValue()" height="117" width="165" alt="">
                            <div class="meter-info">
                                <span class="text-and-icon" *ngIf="firstBelowPowerValue">
                                    <img alt="" [src]="belowIcon" width="16"
                                         height="16"> {{ firstBelowPowerValue }}
                                </span>
                                <span class="text-and-icon" *ngIf="firstAbovePowerValue">
                                    <img alt="" [src]="aboveIcon"
                                         width="16" height="16"> {{ firstAbovePowerValue }}
                                </span>
                            </div>
                        </div>
                        <p [style.width.px]="126" *ngIf="meterPowerValue && !noAlarmState">
                            <b>{{ meterPowerValue }} Watt </b>
                            <span [translate]="meterAboveCenter ? 'screens.dashboard.realTimeAlarmDetail.powerBelowAlarm' : 'screens.dashboard.realTimeAlarmDetail.powerAboveAlarm'"></span>
                        </p>
                    </div>
                    <div class="no-alarms-box" *ngIf="noAlarmState">
                        <p>
                            {{ 'screens.dashboard.realTimeAlarmDetail.noAlarmsAvailable' | translate }}
                        </p>
                    </div>
                    <div class="alarms-wrapper">
                        <div class="alarms-list" *ngFor="let alarm of alarmsData; trackBy: trackByAlarmKey">
                            <div class="info">
                                <div class="title">
                                    <img [ngSrc]="getIconForAlarmType(alarm.type, true)" width="23"
                                         height="23" alt=""/>
                                    <h4><b>{{ alarm.name }}</b></h4>
                                </div>
                                <button class="toggle-button"
                                        [class.activated]="alarm.enabled"
                                        [class.deactivated]="!alarm.enabled"
                                        (click)="toggleAlarms(alarm.enabled, alarm.key)"></button>
                            </div>
                            <div class="details">
                                <p>{{ alarm.threshold_value }} Watt</p>
                                <img [src]="getIconForAlarmType(alarm.type)"
                                     alt="" style="height: 24px; width: auto;"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="manage-button">
                        <button class="eon-button-new turquoise" [disabled]="!chartInitialized"
                                (click)="setMode(alarmViewMode.MANAGE)">
                            {{ 'screens.dashboard.realTimeAlarmDetail.manageAlarms' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="detail-content" *ngIf="currentMode === alarmViewMode.MANAGE">
                <div class="no-alarms-text" *ngIf="noAlarmState">
                    <p>
                        {{ 'screens.dashboard.realTimeAlarmDetail.noAlarmsAvailable' | translate }}
                    </p>
                </div>
                <section class="add-alarms-content" *ngIf="!noAlarmState">
                    <ng-container>
                        <div class="alarm-form"
                             *ngFor="let alarm of alarmsData, let i = index; trackBy: trackByAlarmKey">
                            <div class="alarm-title">
                                <div class="name-and-delete">
                                    <h4>Alarm {{ i + 1 }}</h4>
                                </div>
                                <button class="toggle-button" [class.activated]="alarm.enabled"
                                        [class.deactivated]="!alarm.enabled"
                                        (click)="toggleAlarms(alarm.enabled, alarm.key)"></button>
                            </div>
                            <div class="form-container">
                                <p class="input-label">{{ 'screens.dashboard.realTimeAlarmDetail.alarmNameInput' | translate }}</p>
                                <fieldset class="eon-input">
                                    <input
                                            id="alarm-name"
                                            type="text"
                                            class="eon-input"
                                            [(ngModel)]="alarm.name"
                                            [style.top.px]="14">
                                </fieldset>
                                <span *ngIf="!alarm.name"
                                      class="error">{{ 'screens.dashboard.realTimeAlarmDetail.inputValidationLabel' | translate }}</span>

                                <p class="input-label">{{ 'screens.dashboard.realTimeAlarmDetail.selectConsumption' | translate }}</p>
                                <div class="eon-dropdown" [style.height.px]="56">
                                    <select id="alarm-type" [(ngModel)]="alarm.type"
                                            (change)="updateAlarmType(alarm)">
                                        <option *ngFor="let option of dropDownOptions" [value]="option.value">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                    <span></span>
                                </div>
                                <span *ngIf="!alarm.type"
                                      class="error">{{ 'screens.dashboard.realTimeAlarmDetail.inputValidationLabel' | translate }}</span>

                                <p class="input-label">{{ (alarm.type === "feed_in" ? 'screens.dashboard.realTimeAlarmDetail.consumptionInput2' : 'screens.dashboard.realTimeAlarmDetail.consumptionInput') | translate }}</p>
                                <div class="btn-container">
                                    <div class="input-wrapper">
                                        <input
                                                type="number"
                                                class="iona-input"
                                                maxlength="15"
                                                [(ngModel)]="alarm.threshold_value"
                                                [readonly]="!isEditable(alarm.type)"
                                                [style.cursor]="isEditable(alarm.type) ? 'text' : 'not-allowed'"/>
                                        <div class="input-icon">
                                            <img
                                                    [src]="getIconForAlarm(alarm.type)"
                                                    alt="Icon"
                                                    class="icon"
                                                    loading="lazy"/>
                                        </div>
                                    </div>
                                </div>
                                <span *ngIf="!alarm.threshold_value"
                                      class="error">{{ 'screens.dashboard.realTimeAlarmDetail.inputValidationLabel' | translate }}</span>
                            </div>
                        </div>
                    </ng-container>
                </section>
                <div class="detail-sidebar">
                    <div class="add-alarm-section">
                        <button class="eon-button-new turquoise" [disabled]="alarmsData?.length >= 4"
                                (click)="addAlarm()">
                            {{ 'screens.dashboard.realTimeAlarmDetail.addAlarm' | translate }}
                        </button>
                        <p>{{ 'screens.dashboard.realTimeAlarmDetail.numberOfAlarms' | translate }} {{ alarmsData?.length || 0 }}/4</p>
                    </div>
                    <div class="manage-button">
                        <button class="eon-button-new turquoise" [disabled]="isSaveDisabled" (click)="saveAlarms()">
                            {{ 'screens.dashboard.realTimeAlarmDetail.saveChanges' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <ng-template #emptyState>
                <div class="animation-wrapper">
                    <div class="animation-container"
                         lottie [options]="lottieConfig">
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
