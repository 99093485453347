import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription, timer} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import * as moment from 'moment';
import {UserService} from './user.service';
import {MetaResponse} from '../shared/interfaces/meta-response';

@Injectable({
    providedIn: 'root',
})
export class RealTimeAlertService extends BaseService {
    private closeEventSource = new Subject<void>();
    private timerSub: Subscription = null;
    private realTimeAlertTimerSub: Subscription = null;
    closeEvent$ = this.closeEventSource.asObservable();
    onRealTimeAlertDataUpdate = new BehaviorSubject<MetaResponse>(null);

    private updateRate = 10000;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
        if (this.realTimeAlertTimerSub) {
            this.realTimeAlertTimerSub.unsubscribe();
            delete this.realTimeAlertTimerSub;
        }

    }

    notifyClose(): void {
        this.closeEventSource.next();
    }

    startRealTimeAlertValueLiveUpdate(maxOffset: number): void {
        if (this.realTimeAlertTimerSub) {
            return;
        }

        this.realTimeAlertTimerSub = timer(0, this.updateRate).pipe(
            mergeMap(() => {
                const now = moment();
                const to = now.add(5, 'minutes').toISOString();
                const from = now.subtract(maxOffset + 1, 'days').toISOString();
                return this.requestDataForTimeframe(from, to);
            })
        ).subscribe({
            next: (res: MetaResponse) => {
                this.onRealTimeAlertDataUpdate.next(res);
            },
            error: (error) => {
                console.error('Error in real-time alert timer subscription:', error);
            }
        });
    }


    public requestDataForTimeframe(start: string, end: string): Observable<MetaResponse> {
        return this.http
            .get<MetaResponse>(
                `${this.API_BASE_URL}${constants.api.routes.instantaneousPower}/${start}/${end}/900/max`
            )
            .pipe(
                catchError((error) => {
                    console.error('Error fetching data for timeframe:', error);
                    return of({ status: 'error', data: null } as MetaResponse);
                })
            );
    }
}
